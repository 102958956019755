import { points, members, indonesia_payment } from "../../js/path";
import _ from "lodash";
import axios from "axios";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      historyFields: [
        {
          key: "id",
        },
        {
          key: "points",
          label: "Points",
        },
        {
          key: "type",
        },
        {
          key: "point_credit_debit",
          label: "Points Type",
        },
        {
          key: "note",
        },
      ],
      messages: [],
      params: "",
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,

      fields: [
        {
          key: "id",
        },
        {
          key: "username",
          class: "w-250",
          label: "Username",
        },
        {
          key: "total_points",
          class: "w-250",
          label: "Total Points",
        },
        {
          key: "available_points",
          class: "w-250",
          label: "Available Points",
        },
        {
          key: "redeem_points",
        },
        {
          key: "balance",
          label: "Balance",
        },
        {
          key: "is_active",
          label: "Status",
        },
      ],
      activeTab: "all",
      form: {
        member_id: "",
        transaction_amt: "",
        percentage: "",
        notes: "",
      },
      redeemForm: {
        points: "",
        notes: "",
      },
      memberList: [],
      historyPoints: [],
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) {
        this.fetchData("search");
        this.currentPage = 1;
      } else if (this.filter.length == 0) {
        this.fetchData("search");
        this.currentPage = 1;
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        if (this.pageNo.length > 0) {
          this.currentPage = this.pageNo;
        }
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
          this.currentPage = 1;
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData("search");
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchData(txt) {
      this.activeTab = txt;
      this.$store.commit("loader/updateStatus", true);
      let request = `${indonesia_payment?.getWallet}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}&filter=${this.activeTab}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },

    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      url.searchParams.has("filter")
        ? url.searchParams.set("filter", this.activeTab)
        : url.searchParams.append("filter", this.activeTab);
      if (this.filter != null && this.filter != "") {
        url.searchParams.has("search")
          ? url.searchParams.set("search", this.filter)
          : url.searchParams.append("search", this.filter);
      } else {
        url.searchParams.delete("search");
      }
      if (this.currentPage != null && this.currentPage != "") {
        url.searchParams.has("page")
          ? url.searchParams.set("page", this.currentPage)
          : url.searchParams.append("page", this.currentPage);
      } else {
        url.searchParams.set("page", 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },

    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${indonesia_payment?.updateWalletStatus}/${id}`;
        const data = await axios.post(url, {
          status: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async redeemPoint() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let redeemData = new FormData();
        redeemData.append("member_id", this?.$route?.params?.id);
        redeemData.append("points", this?.redeemForm?.points);
        redeemData.append("notes", this?.redeemForm?.notes);
        let redeemUrl = points?.redeemPoints;
        let data = await this.postRequest(redeemUrl, redeemData);
        if (data?.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/points");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err?.data ? err?.data?.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let pointsData = {};
        pointsData["user_reference_id"] = this?.form?.member_id?.member_id;
        for (var key in this.form) {
          if (key !== "member_id") {
            pointsData[key] = this.form[key];
          }
        }
        let url = indonesia_payment?.getWallet;
        let data = await axios.post(`${apiEndpoint}${url}`, pointsData);
        if (data?.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/points");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err?.data ? err?.data?.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchHistoryPoints() {
      const url = `${points?.getPointHistory}/${this?.$route?.params?.id}`;
      const data = await this.getRequest(url);
      this.historyPoints = data;
      console.log(this.historyPoints);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.query.filter) {
      this.activeTab = this.$route.query.filter;
    }
    if (this.$route.query.search) {
      this.filter = this.$route.query.search;
    }
    if (this.$route.query.page && this.$route.query.page != 1) {
      this.currentPage = parseInt(this.$route.query.page);
    } else if (this.$route.name == "points") {
      this.fetchData(this.activeTab);
    } else if (this.$route.name == "view-points") {
      this.fetchHistoryPoints();
    }
  },
};

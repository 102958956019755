var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"email",staticClass:"row",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Member "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('multiselect',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.member_id.$error,
                },attrs:{"id":"exp","options":_vm.memberList,"multiple":false,"track-by":"member_id","label":"fnameAndLname","placeholder":"Type here to search"},on:{"search-change":_vm.fetchMemberList},model:{value:(_vm.form.member_id),callback:function ($$v) {_vm.$set(_vm.form, "member_id", $$v)},expression:"form.member_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.member_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Member is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-3","label-for":"input-3"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Total Amount "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.transaction_amt.$error,
                },attrs:{"id":"input-3","placeholder":"Enter Total Amount"},model:{value:(_vm.form.transaction_amt),callback:function ($$v) {_vm.$set(_vm.form, "transaction_amt", $$v)},expression:"form.transaction_amt"}}),(_vm.submitted && !_vm.$v.form.transaction_amt.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" total_amount is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-2","label-for":"input-2"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Point Percentage "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.percentage.$error,
                },attrs:{"id":"input-2","placeholder":"Enter Point Percentage","type":"number","min":"0"},model:{value:(_vm.form.percentage),callback:function ($$v) {_vm.$set(_vm.form, "percentage", $$v)},expression:"form.percentage"}}),(_vm.submitted && !_vm.$v.form.percentage.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" percentage is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-2","label-for":"input-2"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Note")]),_c('b-form-textarea',{attrs:{"id":"message","placeholder":"Add note","rows":"4"},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}})],1),_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[_vm._v(" Add Points ")])],1)],1)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }